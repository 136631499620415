import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import ExpertInfoItem from './ExpertInfoItem'
import { Loader, Button, StatusHandler } from '../../../library'
import { Collapse, Panel } from '../../../library/Collapse'

import { getApplicationInfo, clearApplicationInfo, getFeedbacks, sendReviewToRework, approveValidation, PrevApproveValidation, removeExpertFromApp } from '../../../../actions'


class ExpertInfo extends Component {

	state = {
		reviewerMessage: ''
	}

	static getDerivedStateFromProps = ( props, state ) => {
		if( props.feedbacks.data !== null && state.loaded === false){
			const message = props.feedbacks.data.review.reviewer_message;
			if( message !== ''){
				return {
					...state,
					reviewerMessage: message
				}
			}
		}
		return null;
	}


	componentDidMount = () => {
		this.props.getFeedbacksReq();
	}

	handleChange = (e) => {
		this.setState({
			reviewerMessage: e.target.value
		});
	}

	sendToRework = () => {
		this.props.sendToRework( this.state.reviewerMessage );
	}

	approveAppReq = () => {
		this.props.approveValidation( this.state.reviewerMessage );
	}

	prevApprove = () => {
		this.props.prevApprove( this.state.reviewerMessage );
	}

	removeExpert = () => {
		this.props.removeExpertFromApp();
	}

	render = () => {
		const { reviewerMessage } = this.state;
		const { handleChange, sendToRework, approveAppReq, prevApprove, removeExpert } = this;
		const { loaded, feedbacks, application } = this.props;
		console.log('application', application );
		const failureStatuses = [-3, -1 ];

		
		if( !loaded ){
			return <Loader/>;
		}
		const isFailure = failureStatuses.some( status => feedbacks.data.review.status.id === status );
		
		if ( loaded ) {
			// console.log('can remoovved?', feedbacks.data.review.status.id,  feedbacks.data.review.status.id > 1, feedbacks.data.review.status.id < 0);
			return (
				<>
					<header className="expert_info_header">
						<h1> 
							Відгук експерта: { feedbacks.data.review.expert.name} 
							( { feedbacks.data.review.expert.email} )

							<span style={{ marginLeft: '8px'}}> 
								<StatusHandler type="expert_review" statusId={feedbacks.data.review.status.id} />
							</span>
						</h1>	
						<Button
							text="Видалити експерта з заявки"
							color="remove_link"
							handler={removeExpert}
						/>
					</header>

					<div className="text white-space">
						Коментар експерта: {feedbacks.data.review.expert_message}
					</div>
					{
						feedbacks.data.review.reviewer_message && (
							<div className="additional_info text white-space">
								<b>Коментар експертного відділу:</b> {feedbacks.data.review.reviewer_message}
							</div>
						)
					}

					<br />

					{
						feedbacks.data.review.messages.length > 0 && (
							<Link className="expMsg__link" to={`/ed/application/${application._id}/review/${feedbacks.data.review._id}/history`}> Історія переписки </Link>
						)
					}

					{
						feedbacks.data.review.protocol_id !== undefined && feedbacks.data.review.status.id === 5&& (
							<div className="additional_info">
								<a href={feedbacks.data.review.protocol_id.fileUrl} target="_blank" rel="noopener noreferrer" className="application__documents-protocol">
									Завантажити протокол
								</a>
							</div>
						)
					}
					{
						isFailure ? 
						(
							<div className="additional_info">							
								<a href={feedbacks.data.review.protocol_id.fileUrl} target="_blank" rel="noopener noreferrer" className="application__documents-protocol">
									Завантажити протокол відмови
								</a>
								<br />
								<i>Причина відмови: </i> 
								<span> { feedbacks.data.review.status.message } </span> 
							</div>
						) : (
							<>
								<textarea
									className="form-input expert_message"
									name="title"
									onChange={handleChange}
									value={reviewerMessage}
									placeholder=""
									rows="10"
									required
								>
								</textarea>

								<div className="application-expert_apply">
									<button 
										onClick={approveAppReq}
										className={ feedbacks.data.review.status.id !== 2 && feedbacks.data.review.status.id !== 6 ? "application-expert_apply-btn apply disabled" : "application-expert_apply-btn apply"}
										disabled={ feedbacks.data.review.status.id !== 2 && feedbacks.data.review.status.id !== 6 }
									>Підтвердити оцінку екперта</button>

									<button
										onClick={ prevApprove }
										className={ classnames( "application-expert_apply-btn", { disabled: feedbacks.data.review.status.id !== 2 } ) }
										disabled={ feedbacks.data.review.status.id !== 2}
									>
										Попереднє погодження
									</button>

									<button 
										className={ feedbacks.data.review.status.id !== 2  && feedbacks.data.review.status.id !== 6 ? "application-expert_apply-btn disabled" : "application-expert_apply-btn dismiss"}
										disabled={ feedbacks.data.review.status.id !== 2  && feedbacks.data.review.status.id !== 6 }
										onClick={ sendToRework }
									>Відправити оцінку на доопрацювання</button>
								</div>
							</>
						)
					}

					{
						feedbacks.data.feedbacks.map( feedback => {
							let maxPerItem = Number( (feedback.max_score / feedback.questions.length).toFixed() );
							const score = feedback.questions.reduce( ( sum, item ) => {
								return sum + item.score;
							}, 0 );
							return (
							<Collapse className="collapse-experts-main" key={feedback._id}>
								<Panel
									title={ `${feedback.title} ( ${score}/${feedback.max_score} )`}
									opened={ true }
								>
									{ 
										feedback.questions.map( (question, key) => {
											
											if( question.question_id.max ){
												maxPerItem = question.question_id.max;
											}
											
											return (
												<ExpertInfoItem
													key={key}
													question={question}
													title={question.title}
													rate={question.score}
													max={maxPerItem}
												/>
											)
										})
									}
								</Panel>
							</Collapse>
							)}
						)
					}
				</>
			)
		}
	}
}

const mapStateToProps = (state) => ({
	application: state.applications.info.data,
	loaded: state.expert.feedbacks.loaded,
	feedbacks: state.expert.feedbacks
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
	getApplication: (id) => {
		dispatch(getApplicationInfo(id))
	},
	clearApplication: () => {
		dispatch(clearApplicationInfo())
	},
	getFeedbacksReq: () => {
		dispatch( getFeedbacks( ownProps.match.params.reviewid ) );
	},
	sendToRework: (message) => {
		dispatch( sendReviewToRework( message, ownProps.match.params.reviewid ) )	
	},
	approveValidation: (message) => {
		dispatch( approveValidation( message, ownProps.match.params.reviewid ) )	
	},
	prevApprove: (message) => {
		dispatch( PrevApproveValidation( message, ownProps.match.params.reviewid ) )	
	},
	removeExpertFromApp: () => {
		dispatch( removeExpertFromApp( ownProps.match.params.reviewid ) );
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertInfo)