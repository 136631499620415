import React from 'react'

import starDisable from '../icons/star-disable.svg'
import starActive from '../icons/star-active.svg'

import './../styles/expert_info.scss'

const ExpertInfoItem = ({title, question, rate, max}) => {


  // console.log( rate, max);
  let lib = [];
  if( max === 3){
    lib = [
      {
        title: "Слабко",
        score: 1
      },
      {
        title: "Посередньо",
        score: 2
      },
      {
        title: "Дуже добре ",
        score: 3
      }
    ]
  }

  if( max === 5){
    lib = [
      {
        title: "Слабко ",
        score: 1
      },
      {
        title: "Нижче середнього",
        score: 2
      },
      {
        title: "Посередньо",
        score: 3
      },
      {
        title: "Добре",
        score: 4
      },
      {
        title: "Дуже добре",
        score: 5
      },
    ]
  }

  if( max === 10){
    lib = [
      {
        title: "Слабко",
        score: 1
      },
      {
        title: "Слабко",
        score: 2
      },
      {
        title: "Нижче середнього",
        score: 3
      },
      {
        title: "Нижче середнього",
        score: 4
      },
      {
        title: "Посередньо",
        score: 5
      },
      {
        title: "Посередньо",
        score: 6
      },
      {
        title: "Добре",
        score: 7
      },
      {
        title: "Добре",
        score: 8
      },
      {
        title: "Дуже добре",
        score: 9
      },
      {
        title: "Дуже добре",
        score: 10
      },
    ]
  }

  
  const rateItem = lib.find( item => Number(rate) === item.score );
  const starsArray = new Array( max ).fill(true);


  return(
  <div className="collapse-experts-review">
    <div className="collapse-experts-review-info">
      <div className="collapse-experts-review-info-wrap">
        <div className="collapse-experts-review-text">{title}</div>
      </div>
      <div className="collapse-experts-review-apply">
          <div className="collapse-experts-review-solution">
            {
              rateItem && ( rateItem.title )
            }
          </div>
          <div className="collapse-experts-review-stars">
            <div className="collapse-experts-review-stars-wrap">
              {
                starsArray.map( (star, index) => {
                  let src = starDisable;
                  if( index+1 <= rate ){
                    src = starActive;
                  }
                  return ( <img key={index} src={src} alt="star"/> );
                })
              }
            </div>
            <div className="collapse-experts-review-rate">{rate}/{max}</div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default ExpertInfoItem